import React from 'react';
import Chat from '../components/Chat';
import HeaderConversao from '../components/Conversao';
import TableConversao from '../components/Conversao/Table';
import Footer from '../components/Footer';
import Layout from '../components/Layout';
import SEO from '../components/seo';

export default function Cotacao() {
  return (
    <Layout>
      <SEO title="Conversão" />
      <HeaderConversao />
      <TableConversao />
      <Footer />
      <Chat />
    </Layout>
  );
}
