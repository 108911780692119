import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import LogoHubfintechBranco from '../../images/svg/Logo-hubfintech-branco.svg';
import Menu from '../Menu';
import { Container, EN, ES, Header, PT } from './styles';

export default function HeaderCotacao() {
  const { idioma } = useContext(LanguageContext);

  var title = ['Taxas de Conversão', 'Conversion Rates', 'Tasas de Conversión'];

  return (
    <Container>
      <Header>
        <Link to="/">
          <img src={LogoHubfintechBranco} alt="Hubfintech somos hub" />
        </Link>
        <h2>
          {title[idioma]}
          <hr />
        </h2>
      </Header>
      <Menu scrollToForm={() => console.log('to form')} />
    </Container>
  );
}

/*
//BANDEIRAS PARA TRADUÇÃO
*/
